<template>
    <v-container fluid>
        <v-form @submit.prevent="getEmergencyCalls()">
            <v-btn v-if="$route.params.status === 'new'" v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'emergency_call.create'}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <div class="d-flex pb-2">
                    <div class="mr-auto">
                        <v-subheader class="headline px-0 mx-0">
                            {{ $route.params.status==='new' ? $t('menu_emergency_calls') :  $t('menu_emergency_call_'+$route.params.status) }}
                            <v-btn v-if="$vuetify.breakpoint.xsOnly" class="ml-1"  text  icon  @click="toggleFilter" >
                                <v-icon color="indigo" small>mdi-cog</v-icon>
                            </v-btn>
                        </v-subheader>
                    </div>
                    <div v-if="!$vuetify.breakpoint.xsOnly && $route.params.status==='new'">
                        <v-btn :to="{name: 'emergency_call.create'}" class="white--text mt-1" color="indigo">
                            {{ $t('create') }}
                        </v-btn>
                    </div>
                </div>
                <v-row v-if="!$vuetify.breakpoint.xsOnly || filter">

                    <v-col cols="12" sm="3">
                        <ValidationProvider ref="phone" rules="phone"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                          :error-messages="errors"
                                          :disabled="loading"
                                          :label="$t('phone')"
                                          clearable
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          persistent-hint
                                          hide-details
                            >
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <ValidationProvider ref="symptom_category" rules="min:1" v-slot="{ errors, valid }">
                            <v-select v-model="symptom_category" :disabled="loading" :items="symptomCategoryItems"
                                      :error="!valid" :error-messages="errors"
                                      color="primary" item-text="name" item-value="id"
                                      :label="$t('symptom_category')"
                                      clearable
                                      outlined
                                      rounded
                                      dense
                                      class="rounded-lg"
                                      persistent-hint
                                      hide-details></v-select>
                        </ValidationProvider>
                    </v-col>


                    <v-col cols="12" sm="6">
                        <ValidationProvider ref="caller_name" rules="min:3"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="caller_name" type="text" :error-messages="errors"
                                          :disabled="loading"
                                          :label="$t('caller_name')" color="primary"
                                          clearable
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          persistent-hint
                                          hide-details

                            ></v-text-field>
                        </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="mr-auto">
                                <v-select v-if="$vuetify.breakpoint.xsOnly" :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          persistent-hint
                                          hide-details></v-select>
                            </div>
                            <div>
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-form>
        <v-row class="pt-5">
            <v-col>
                <v-data-table :headers="headers" :items="emergency_callItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalPages" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="cursor_pointer elevation-1"
                              hide-default-footer
                              @click:row="showEmergencyCall"

                >
                    <template v-slot:item.caller_phone="{ item }">
                        <div>{{formatPhoneNumber(item.caller_phone)}}</div>
                    </template>
                    <template v-slot:item.patient="{ item }">
                        <div>
                            {{ item.patient.street }}, {{$t('house')}} {{ item.patient.house }},
                            {{$t('entrance')}} {{ item.patient.entrance || 'N/A' }}, {{$t('floor')}} {{ item.patient.floor || 'N/A' }},
                            {{$t('apartment')}} {{ item.patient.apartment || 'N/A' }},
                            {{$t('block')}} {{ item.patient.block || 'N/A' }}
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row >
            <v-col cols="9" class="d-flex justify-start " v-if="!$vuetify.breakpoint.xsOnly">
                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                          :label="$t('items_per_page')"
                          @input="perPage = options.itemsPerPage = Number($event)"
                          outlined
                          rounded
                          dense
                          class="rounded-lg"
                          persistent-hint
                          hide-details></v-select>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 3" class="d-flex justify-end px-0">
                <v-pagination
                    class="rounded-md"
                    color="primary"
                    v-model="page"
                    :total-visible="totalVisiblePage"
                    :length="pageCount"
                    :disabled="loading"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import {mask} from "vue-the-mask";

export default {
    name: "EmergencyCalls",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            filter: false,
            phoneRaw: null,
            caller_name: null,
            sortBy: "id",
            sortDir: false,
            loading: false,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePage: 5,
            totalPages: 0,
            emergency_callItems: [],
            headers: [
                {
                    text: this.$t('symptom_category'),
                    align: "left",
                    sortable: false,
                    value: "symptom_category.name"
                },
                {
                    text: this.$t('caller_name'),
                    align: "left",
                    sortable: false,
                    value: "caller_name"
                },
                {
                    text: this.$t('caller_phone'),
                    align: "left",
                    sortable: false,
                    value: "caller_phone"
                },
                {
                    text: this.$t('address'),
                    align: "left",
                    sortable: false,
                    value: "patient"
                }
            ],
            symptom_category: null,
            symptomCategoryItems: [],
        }
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getSymptomCategories()
    },
    watch: {
        options: {
            handler() {
                this.getEmergencyCalls()
            },
            deep: false
        }
    },
    methods: {
        toggleFilter() {
            this.filter = !this.filter;
        },
        async getEmergencyCalls() {
            var _this = this
            this.progress = 0
            this.loading = true
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options

            let params = {}

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.$route.params.type) {
                params.type = this.$route.params.type
            }
            if (this.$route.params.status) {
                params.status = this.$route.params.status
            }
            if (this.phone) {
                params.caller_phone = this.phone
            }
            if (this.caller_name) {
                params.caller_name = this.caller_name
            }
            if (this.symptom_category) {
                if (this.symptom_category.id) {
                    params.symptom_category_id = this.symptom_category.id
                } else {
                    params.symptom_category_id = this.symptom_category
                }
            }

            await this.$http
                .get("admin/emergency_call", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.emergency_callItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalPages = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_emergency_calls'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async deleteEmergencyCall(item) {
            if (confirm(this.$t('delete_emergency_call'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/emergency_call/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('emergency_call_has_been_deleted'))
                        this.getEmergencyCalls()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('emergency_call_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        showEmergencyCall(item) {
            this.$router.push({
                name: 'emergency_call.show',
                params: {
                    id: item.id,
                    status: this.$route.params.status
                }
            });
        },
        async getSymptomCategories() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/symptom_category", {
                    params: params,
                })
                .then(res => {
                    this.symptomCategoryItems = res.body.data
                })
                .catch(err => {
                    this.symptomCategoryItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
